<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edite school')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.name"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('name')"
                />
                <small
                  v-if="errors.name"
                  class="text-danger"
                >{{ errors.name[0] }}</small>
                <label>{{ $t('name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('Unavailable/Available') }}</label>
                <b-form-checkbox
                  v-model="data.status"
                  :value="true"
                  :state="errors.status ?false:null"
                >
                  {{ data.status ? $t('Unavailable') : $t('Available') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.status"
                class="text-danger"
              >{{ errors.status[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.description"
                  class="mt-2"
                  :state="errors.description ?false:null"
                  :placeholder="$t('description')"
                />
                <small
                  v-if="errors.description"
                  class="text-danger"
                >{{ errors.description[0] }}</small>
                <label>{{ $t('description') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.lan"
                  class="mt-2"
                  :state="errors.lan ?false:null"
                  :placeholder="$t('lan')"
                />
                <small
                  v-if="errors.lan"
                  class="text-danger"
                >{{ errors.lan[0] }}</small>
                <label>{{ $t('lan') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.LAT"
                  class="mt-2"
                  :state="errors.LAT ?false:null"
                  :placeholder="$t('LAT')"
                />
                <small
                  v-if="errors.LAT"
                  class="text-danger"
                >{{ errors.LAT[0] }}</small>
                <label>{{ $t('LAT') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.phone"
                  class="mt-2"
                  :state="errors.phone ?false:null"
                  :placeholder="$t('phone')"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ errors.phone[0] }}</small>
                <label>{{ $t('phone') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.facebook"
                  class="mt-2"
                  :state="errors.facebook ?false:null"
                  :placeholder="$t('facebook')"
                />
                <small
                  v-if="errors.facebook"
                  class="text-danger"
                >{{ errors.facebook[0] }}</small>
                <label>{{ $t('facebook') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.telegram"
                  class="mt-2"
                  :state="errors.telegram ?false:null"
                  :placeholder="$t('telegram')"
                />
                <small
                  v-if="errors.telegram"
                  class="text-danger"
                >{{ errors.telegram[0] }}</small>
                <label>{{ $t('telegram') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      ><b-card>
         <b-row>
           <b-col
             cols="12"
             md=""
             class="d-flex justify-content-center"
           >
             <b-row>
               <b-col
                 cols="12"
                 class="d-flex justify-content-center"
               >
                 <upload
                   v-model="data.img_id"
                   @on-file-error="UpdateFileError"
                 />
               </b-col>
               <b-col cols="12">
                 <b-alert
                   v-if="fileErrors.length>0"
                   variant="danger"
                   show
                 >
                   <h4 class="alert-heading">
                     {{ $t('Upload Image Error') }}
                   </h4>
                   <div class="alert-body">
                     <span>
                       <ul
                         v-for="(val,index) in fileErrors"
                         :key="index"
                       >
                         <li>{{ val }}</li>
                       </ul>
                     </span>
                   </div>
                 </b-alert>
               </b-col>
             </b-row>
           </b-col>
         </b-row>
       </b-card>
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="update"
              >
                {{ $t('Update') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['school/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('school/GetElement', this.$route.params.school).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('school/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
